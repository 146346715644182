.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.App {
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: none;
}
.map-container {
  flex: 1;
}
.map-container2 {
  flex: 1;
  width: 500px;
  height: 200px;
  border-radius: 8px;
}
.map-container-thumbnail {
  flex: 1;
  width: 70px;
  height: 70px;
  border-radius: 8px;
}
#map {
  width: 100%;
  height: 100%;
  position: absolute;
}
#map-view {
  display: flex;
  flex-direction: row;
  position: relative;
}
#map-view #sidebar {
  width: 340px;
}
#map-view #sidebar,
#map-view #sidebar + * {
  transition: width 0.25s ease;
}
#map-view #sidebar.wide {
  width: 480px;
}
#map-view #sidebar.wide + * {
  width: calc(100vw - 480px);
}
#map-view #sidebar + * {
  overflow: hidden;
  display: flex;
  justify-content: center;
  width: calc(100vw - 340px);
}
#map-view #sidebar + * > * {
  flex-shrink: 0;
  width: calc(100vw - 340px);
}
#map-view #container {
  display: block;
}
#map-view #container.blocked {
  pointer-events: none;
}
#map-view #container .ant-spin {
  z-index: 2;
  margin-left: calc(100vw / 2) !important;
}
.leaflet-control-container .leaflet-control-layers {
  display: none;
}
.leaflet-draw.leaflet-control {
  top: 8vh;
  z-index: 1020;
  right: 1vh;
  text-align: left;
}
.leaflet-draw-toolbar a:first-child {
  border-top-right-radius: 8px !important;
  border-top-left-radius: 8px !important;
}
.leaflet-draw-toolbar a:last-child {
  border-bottom-right-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}
.list-item:hover {
  box-shadow: 0 0 10px #1890ff;
}
.page-enter {
  opacity: 0;
}
.page-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.page-exit {
  opacity: 1;
}
.page-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}
.left-sidebar-trigger {
  display: "block";
  font-size: 18px;
  line-height: 64px;
  text-align: center;
  cursor: pointer;
  color: #282c34;
  transition: color 0.3s;
}
.trigger {
  font-size: 18px;
  line-height: 64px;
  text-align: center;
  cursor: pointer;
  color: #282c34;
  transition: color 0.3s;
}
.trigger:hover {
  color: #1890ff;
}
@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes slide-out {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-100%);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* Apply the animation to the Layout component */
.view-layout {
  animation: fadeIn 2s;
  padding: 15px;
}
/* Define the animation effect */
/*
}*/
/*.page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.page-enter-active {
  opacity: 1;
  transform: translateX(0);
  filter: blur(0);
  transition: opacity 500ms ease-out, transform 500ms ease-out, filter 500ms ease-out;
}

.page-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  filter: blur(10px);
  transition: opacity 500ms ease-in, transform 500ms ease-in, filter 500ms ease-in;
}*/
/*.page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}*/
.page-enter {
  opacity: 0;
  transform: translateX(100%);
}
.page-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms ease-in, transform 500ms ease-in;
}
.page-exit {
  opacity: 1;
  transform: translateX(0);
}
.page-exit-active {
  transform: translateX(-100%);
}
.centered-cell {
  text-align: center !important;
  /* Horizontal alignment */
  vertical-align: middle !important;
  /* Vertical alignment */
  white-space: nowrap;
}
.field-compare-table-indices {
  width: 300px;
}
.table-cell-chart-container {
  width: 100%;
  height: 100%;
}
.text-left-cell {
  text-align: left !important;
}
.timeline-date.active {
  color: green;
}
.layout-change-segmented {
  text-align: right;
  margin: auto;
}
.data-avatars-card {
  margin-bottom: 10px;
  margin-top: 5px;
}
.data-avatars-layout {
  background-color: transparent;
  margin: auto;
  width: 100%;
  margin-top: 10px;
}
.data-table-layout {
  background-color: transparent;
  margin: auto;
  width: 100%;
  margin-top: 10px;
}
.view-loading-overlay {
  width: 80%;
  height: 92vh;
  background-color: rgba(255, 255, 255, 0.01);
  position: absolute;
  z-index: 1500;
  backdrop-filter: blur(2px);
  right: 0;
  bottom: 0;
}
.view-loading-overlay.fit-to-space {
  width: 100% !important;
  height: 100% !important;
  background-color: rgba(255, 255, 255, 0.01);
  position: absolute;
  z-index: 1500;
  backdrop-filter: blur(2px);
}
.view-loading-overlay.fit-to-space.rounded {
  border-radius: 8x;
}
.view-loading-overlay-content {
  width: 50%;
  height: 25%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.view-loading-overlay-content-sidebar {
  text-align: center;
  position: absolute;
  margin: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ant-spin-dot-item {
  background-color: #4caf50 !important;
}
.ant-spin-text {
  color: #4caf50 !important;
}
.ant-table-center-header-text .ant-table-thead th {
  text-align: center;
}
.search-bar {
  display: flex;
  width: 100%;
  align-items: center;
}
.search-bar .anticon-search {
  color: rgba(0, 0, 0, 0.25);
}
.search-bar-content {
  width: 90%;
}
.search-bar-content .ant-divider {
  margin: 5px !important;
}
.search-bar-content .ant-divider :last-child {
  margin-bottom: 10px;
}
.search-toolbar-dropdown-button {
  background-color: white !important;
}
.search-toolbar-dropdown-button .ant-btn,
.search-toolbar-dropdown-button .ant-btn-default,
.search-toolbar-button .ant-btn {
  padding: 0px !important;
  border: none;
  box-shadow: none;
}
.search-toolbar-assignees,
.search-toolbar-assignee {
  margin: auto;
  cursor: pointer;
}
.search-toolbar-assignees .ant-space .ant-avatar-group {
  vertical-align: middle;
}
.search-toolbar-assignees:hover > .ant-space .ant-space-item .ant-avatar-group .ant-avatar {
  border: 1px solid #eee;
  box-shadow: 0 0 5px #eee;
}
.divider-dropdown-title {
  text-decoration: none !important;
  cursor: pointer;
  box-shadow: inset 0 -0.3em hsla(134, 85%, 46%, 0.4);
  transition: box-shadow 0.35s ease, filter 0.5s ease;
}
.divider-dropdown-title:hover {
  text-decoration: none !important;
  color: "red";
  box-shadow: inset 0 -0.8em hsla(134, 85%, 46%, 0.4);
  transition: box-shadow 0.35s ease, filter 0.5s ease;
}
.field-info-card-img {
  border-radius: 8px;
}
.fields-list-cell-indicators * {
  font-size: 12px;
}
.fields-list-cell {
  cursor: pointer;
  padding: 0px !important;
  margin-bottom: 8px;
}
.fields-list-cell .ant-list-item-meta .ant-list-item-meta-content .ant-list-item-meta-title {
  margin-block-end: 0px;
}
.fields-list-cell .ant-list-item-meta .ant-list-item-meta-avatar {
  padding-top: 5px;
}
.fields-list-cell.highlight-cell {
  box-shadow: 0 0 5px 5px rgba(255, 255, 255, 0.1);
  filter: brightness(150%);
}
.fields-list-cell:hover {
  box-shadow: 0 0 5px 5px rgba(255, 255, 255, 0.1);
  filter: brightness(150%);
}
.positive-delta {
  color: #4CAF50;
  cursor: pointer;
}
.negative-delta {
  color: #f44336;
  cursor: pointer;
}
.user-profile {
  text-align: left;
  margin: 20px;
  width: 100%;
}
.link-to-something {
  vertical-align: super;
  display: none;
  font-size: 10px;
}
.table-row:hover > td .link-to-something {
  display: inline;
}
.table-row-clickable-cell {
  white-space: wrap;
}
.table-row-clickable-cell:hover {
  color: black;
}
.table-row-clickable-cell:hover .table-row-clickable-cell-text {
  font-weight: bolder;
  box-shadow: inset 0 -0.2em hsla(134, 85%, 46%, 0.4);
}
.crop-growth-notes-item {
  width: 50%;
}
.color {
  width: 0.75rem;
  height: 0.75rem;
  margin-right: 0.25rem;
}
.label {
  font-weight: bold;
}
.custom-tooltip-title {
  margin: 0px 12px;
  padding: 72px 0 8px;
  font-size: 12px;
  border-bottom-style: solid;
  border-bottom-width: thin;
  border-bottom-color: #e9e9e9;
}
.custom-tooltip-value {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 8px 12px 0 12px;
  padding-bottom: 8px;
  font-size: 40px;
  text-align: center;
  border-bottom-style: solid;
  border-bottom-width: thin;
  border-bottom-color: #e9e9e9;
  height: 70px;
}
.custom-tooltip-temp {
  display: flex;
  position: relative;
  align-items: center;
}
.custom-tooltip-temp span:first-child {
  font-size: 12px;
  position: absolute;
  top: 0px;
  color: rgba(0, 0, 0, 0.45);
}
.custom-tooltip-temp span:last-child {
  text-align: left;
  margin-top: 10px;
  position: relative;
  color: rgba(0, 0, 0, 0.85);
}
.custom-tooltip-wind {
  margin: 8px 12px 12px 12px;
  font-size: 10px;
  color: rgba(0, 0, 0, 0.45);
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.tooltip-footer {
  margin: 8px 12px 12px 12px;
  font-size: 10px;
  color: rgba(0, 0, 0, 0.45);
}
.background-image {
  background-repeat: no-repeat;
}
.rain {
  background-image: url(https://gw.alipayobjects.com/mdn/rms_2274c3/afts/img/A*sg5aT7dY36wAAAAAAAAAAABkARQnAQ);
}
.sun {
  background-image: url(https://gw.alipayobjects.com/mdn/rms_2274c3/afts/img/A*gE_hS5JVl5YAAAAAAAAAAABkARQnAQ);
}
.cloud {
  background-image: url(https://gw.alipayobjects.com/mdn/rms_2274c3/afts/img/A*BTw4R4O341AAAAAAAAAAAABkARQnAQ);
}
.ant-menu-item-selected,
.ant-menu-submenu-selected {
  background-color: #D0FBDA !important;
  font-weight: bold !important;
  color: "#18191B";
}
.ant-menu-item:hover {
  background-color: #D0FBDA !important;
  font-weight: bold !important;
}
.ant-menu-submenu-title:hover {
  background-color: #D0FBDA !important;
  font-weight: bold !important;
}
.feature-label {
  background-color: #FFFFFF;
  color: #12D940;
  font-size: 10px;
  font-weight: bold;
  border: none;
}
.float-left-space .ant-space-item {
  float: left;
}
.cascader-popup-zindex {
  z-index: 2147483002 !important;
}
.rangepicker-popup-zindex {
  z-index: 2147483001;
}
.ant-popover.ant-color-picker.colorpicker-popup-zindex {
  z-index: 2147483003 !important;
}
.ant-drawer-content-wrapper:has(.action-sheet) {
  border-radius: 15px;
}
.infozone {
  margin: 25px 10px;
  width: 200px;
  height: 100px;
  background: #ffffff;
  box-shadow: 0 0 15px 10px #ffffff;
  padding: 10px;
  filter: drop-shadow(0 0 10px #ffffff);
}
