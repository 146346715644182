* {
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
  &::selection {
    color: unset;
    background-color: hsla(var(--text-color-hsl), .1);
  }
}
a, button {
  cursor: pointer;
}
br {
  user-select: none;
}
img {
  user-select: none;
  vertical-align: middle;
}
svg {
  pointer-events: none;
  user-select: none;
}
ol {
  list-style: decimal;
  list-style-position: inside;
}
