.index-legend-info {
    //left: calc(560px + 1rem);
    border-radius: 10px;
    border: .15rem solid var(--shade-color);
    position: absolute;
    bottom: 0.5rem;
    z-index: 1007;
    & > * {
      color: var(--text-strong-color);
      font-size: .55em;
      font-weight: 400;
      background-color: var(--background-color);
      border-radius: 7px;
      line-height: 0.75rem;
      padding-top: 0.25rem;
    }
    .index-legend {
      & > * {
        padding-left: 0.25rem;
        padding-right: 0.25rem;
      }
      & .index-info {
        width: 24.5rem;
        & :last-child {
          float: right;
        }
      }
      & .color-list{
        height: 0.5rem;
        & > * {
          width: 1.2rem;
          height: 0.3rem;
          display: block;
          float: left;
        }
      }
    }
  }
  