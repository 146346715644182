:root {
  color-scheme: light dark;
  --hue: 228;
  --brand-hue: 134;
  --saturation: 18%;
  --brand-saturation: 78%;
  --brand-color-hsl: var(--brand-hue), var(--brand-saturation), 46%;
  --brand-color: hsl(var(--brand-color-hsl));
  --brand-darker-color-hsl: var(--brand-hue), var(--brand-saturation), 42%;
  --brand-darker-color: hsl(var(--brand-darker-color-hsl));
  --brand-lighter-color-hsl: var(--brand-hue), var(--brand-saturation), 50%;
  --brand-lighter-color: hsl(var(--brand-lighter-color-hsl));
  --text-color-hsl: var(--hue), var(--saturation), 40%;
  --text-color: hsl(var(--text-color-hsl));
  --text-strong-color-hsl: var(--hue), var(--saturation), 25%;
  --text-strong-color: hsl(var(--text-strong-color-hsl));
  --text-weak-color-hsl: var(--hue), var(--saturation), 60%;
  --text-weak-color: hsl(var(--text-weak-color-hsl));
  --foreground-color-hsl: var(--hue), var(--saturation), 100%;
  --foreground-color: hsl(var(--foreground-color-hsl));
  --ground-color-hsl: var(--hue), var(--saturation), 94%;
  --ground-color: hsl(var(--ground-color-hsl));
  --background-color-hsl: var(--hue), var(--saturation), 97%;
  --background-color: hsl(var(--background-color-hsl));
  --shade-color: hsla(var(--text-color-hsl), 0.1);
}
:root.dark {
  --saturation: 6%;
  --text-color-hsl: var(--hue), var(--saturation), 80%;
  --text-strong-color-hsl: var(--hue), var(--saturation), 100%;
  --text-weak-color-hsl: var(--hue), var(--saturation), 60%;
  --foreground-color-hsl: var(--hue), var(--saturation), 12%;
  --ground-color-hsl: var(--hue), var(--saturation), 16%;
  --background-color-hsl: var(--hue), var(--saturation), 14%;
  --shade-color: rgba(0, 0, 0, 0.2);
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
q:before,
blockquote:after,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
* {
  box-sizing: border-box;
}
*:focus {
  outline: none;
}
*::selection {
  color: unset;
  background-color: hsla(var(--text-color-hsl), 0.1);
}
a,
button {
  cursor: pointer;
}
br {
  user-select: none;
}
img {
  user-select: none;
  vertical-align: middle;
}
svg {
  pointer-events: none;
  user-select: none;
}
ol {
  list-style: decimal;
  list-style-position: inside;
}
* {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 300;
  line-height: 150%;
  letter-spacing: -0.0225em;
}
p {
  line-height: 1.25em;
  margin-bottom: 0.5em;
}
p:last-child {
  margin-bottom: 0;
}
a {
  text-decoration: none;
}
i {
  font-style: italic;
}
small {
  font-size: 0.75em;
}
input,
textarea {
  letter-spacing: -0.02em;
}
h1,
h2,
h3,
h4,
h5,
h6,
b,
strong {
  color: var(--text-strong-color);
  font-weight: 700;
}
p strong,
span strong,
small strong,
ul strong,
ol strong {
  color: var(--text-strong-color);
  font-weight: 500;
}
[class^="text-big"] {
  margin-bottom: 0.5em;
  color: var(--text-color);
  line-height: 125%;
}
[class^="text-big"]:last-child {
  margin-bottom: 0;
}
.text-biggest {
  font-size: 2rem;
  letter-spacing: -0.0375em;
}
.text-bigger {
  font-size: 1.75rem;
  letter-spacing: -0.035em;
}
.text-big {
  font-size: 1.25rem;
  letter-spacing: -0.0325em;
}
.text-lead {
  font-size: 1.1em;
  line-height: 150%;
  letter-spacing: -0.03em;
}
.text-detail {
  color: hsla(var(--text-color-hsl), 0.5);
  font-size: 0.75em;
  line-height: 150%;
  letter-spacing: -0.015em;
}
.text-single {
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* 1440 ≤ Window Width */
@media only screen and (min-width: 1440px) {
  html {
    font-size: 22px;
  }
}
/* 1280 ≤ Window Width < 1440 */
@media only screen and (min-width: 1280px) and (max-width: 1439px) {
  html {
    font-size: 20px;
  }
}
/* 1024 ≤ Window Width < 1280 */
@media only screen and (min-width: 1024px) and (max-width: 1279px) {
  html {
    font-size: 18px;
  }
}
html,
body {
  min-width: 1024px;
  color: var(--text-color);
  background-color: var(--background-color);
}
