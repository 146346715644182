// Light
:root {
  color-scheme: light dark;
  // Hue
  --hue: 228;
  --brand-hue: 134;
  // Saturation
  --saturation: 18%;
  --brand-saturation: 78%;
  // Brand colors
  --brand-color-hsl: var(--brand-hue), var(--brand-saturation), 46%;
  --brand-color: hsl(var(--brand-color-hsl));
  --brand-darker-color-hsl: var(--brand-hue), var(--brand-saturation), 42%;
  --brand-darker-color: hsl(var(--brand-darker-color-hsl));
  --brand-lighter-color-hsl: var(--brand-hue), var(--brand-saturation), 50%;
  --brand-lighter-color: hsl(var(--brand-lighter-color-hsl));
  // Text colors
  --text-color-hsl: var(--hue), var(--saturation), 40%;
  --text-color: hsl(var(--text-color-hsl));
  --text-strong-color-hsl: var(--hue), var(--saturation), 25%;
  --text-strong-color: hsl(var(--text-strong-color-hsl));
  --text-weak-color-hsl: var(--hue), var(--saturation), 60%;
  --text-weak-color: hsl(var(--text-weak-color-hsl));
  // Ground colors
  --foreground-color-hsl: var(--hue), var(--saturation), 100%;
  --foreground-color: hsl(var(--foreground-color-hsl));
  --ground-color-hsl: var(--hue), var(--saturation), 94%;
  --ground-color: hsl(var(--ground-color-hsl));
  --background-color-hsl: var(--hue), var(--saturation), 97%;
  --background-color: hsl(var(--background-color-hsl));
  // Shade color
  --shade-color: hsla(var(--text-color-hsl), .1);
}

// Dark
:root.dark {
  // Saturation
  --saturation: 6%;
  // Text colors
  --text-color-hsl: var(--hue), var(--saturation), 80%;
  --text-strong-color-hsl: var(--hue), var(--saturation), 100%;
  --text-weak-color-hsl: var(--hue), var(--saturation), 60%;
  // Ground colors
  --foreground-color-hsl: var(--hue), var(--saturation), 12%;
  --ground-color-hsl: var(--hue), var(--saturation), 16%;
  --background-color-hsl: var(--hue), var(--saturation), 14%;
  // Shade color
  --shade-color: fade(black, 20%);
}
