.map-timeline {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 1px var(--shade-color);
  display: flex;
  bottom: 2.5rem;
  align-items: center;
}
.map-timeline > * {
  height: 1.75rem;
  color: var(--text-strong-color);
  font-size: 0.75em;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  display: flex;
}
.map-timeline > * > * {
  background-color: var(--background-color);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.map-timeline > * > * > *:hover {
  color: #1ad356;
}
.map-timeline > * > * > *.active {
  color: #1ad356;
}
.map-timeline > * > *:hover svg {
  fill: var(--text-strong-color);
}
.map-timeline > * > button {
  width: 1.75rem;
  border-radius: 0 0 7px 7px;
}
.map-timeline > * > button:hover {
  color: #1ad356;
}
.map-timeline .timeline {
  overflow: scroll;
}
.map-timeline .timeline > * {
  min-width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: bold;
  border: none;
}
@media only screen and (min-width: 576px) {
  .map-timeline .timeline > * {
    font-size: 14px;
  }
}
.map-timeline .timeline > *:hover {
  color: #1ad356;
}
.map-timeline .icon {
  fill: var(--text-weak-color);
  vertical-align: middle;
}
.map-timeline .selected {
  color: #1ad356;
}
.map-timeline .prev-button {
  transform: rotate(90deg);
}
.map-timeline .next-button {
  transform: rotate(-90deg);
}
.map-timeline .loading {
  width: calc((100vw - 560px) - 2rem);
  background-color: var(--background-color);
  border-radius: 7px;
}
.map-timeline .ant-spin {
  margin-bottom: 0.5rem;
}
::-webkit-scrollbar {
  display: none;
}
/*
.map-timeline .prev-button {
  background-color: "blue";
    transform: rotate(90deg);
   
}
.map-timeline .next-button {
  background-color: "blue";
    transform: rotate(-90deg);
   
}

.map-timeline .timeline {
    overflow: scroll;
    white-space: nowrap;
}

  .left-arrow,
  .right-arrow {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    background-color: transparent;
    border: none;
    outline: none;
  
    @media only screen and (min-width: 576px) {
      display: block;
    }
  }
  
  .left-arrow {
   //left: 16px;
   color:black;
    background-color: "blue";
  }
  
  .right-arrow {
    //right: 16px;
    color:black;
    background-color: "blue";
  }
*/
