
  .map-timeline{
    border-radius: 10px;
    //border: 0.15rem solid var(--shade-color);
    box-shadow:  0px 0px 5px 1px var(--shade-color);
    display: flex;
    bottom: 2.5rem;
    align-items: center;
   // width: 90%;
    //justify-content: center;
    & > * {
      height: 1.75rem;
      color: var(--text-strong-color);
      font-size: .75em;
      font-weight: 600;
      text-align: center;
      cursor: pointer;
      display: flex;
      & > * {
        background-color: var(--background-color);
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        //border: 1px solid red;        
        & > *:hover {
          color: #1ad356;
        }
        & > *.active {
          color: #1ad356;
        }
        &:hover svg {
          fill: var(--text-strong-color);
        }
        & > span {
         // padding-bottom: .25rem;
        }
      }
      & > button {
        width: 1.75rem;
        border-radius: 0 0 7px 7px;
      }
      & > button:hover {
        color: #1ad356;
      }
    }
    & .timeline {
      overflow: scroll;
      //white-space: nowrap;
      //background-color: turquoise;
      & > * {
        min-width: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        //background-color: yellow;
        font-size: 10px;
        font-weight: bold;
        border: none;
        //background-color: var(--background-color);
        border: none;
        @media only screen and (min-width: 576px) {
          font-size: 14px;
        }
      }
      & > *:hover{
        color: #1ad356;
      }
    }
    & .icon {
      fill: var(--text-weak-color);
      vertical-align: middle;
    }
    & .selected {
      color: #1ad356;
    }
    & .prev-button {
      transform: rotate(90deg);
    }
    & .next-button {
      transform: rotate(-90deg);
    }
    & .loading {
      width: calc((100vw - 560px) - 2rem);
      background-color: var(--background-color);
      border-radius: 7px;
    }
    & .ant-spin {
      margin-bottom: .5rem;
    }
  }
  ::-webkit-scrollbar {
    display: none;
}


/*
.map-timeline .prev-button {
  background-color: "blue";
    transform: rotate(90deg);
   
}
.map-timeline .next-button {
  background-color: "blue";
    transform: rotate(-90deg);
   
}

.map-timeline .timeline {
    overflow: scroll;
    white-space: nowrap;
}

  .left-arrow,
  .right-arrow {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    background-color: transparent;
    border: none;
    outline: none;
  
    @media only screen and (min-width: 576px) {
      display: block;
    }
  }
  
  .left-arrow {
   //left: 16px;
   color:black;
    background-color: "blue";
  }
  
  .right-arrow {
    //right: 16px;
    color:black;
    background-color: "blue";
  }
*/
  