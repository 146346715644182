* {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}
body {
  font-family: -apple-system,
               BlinkMacSystemFont,
               "Segoe UI",
               Roboto,
               Oxygen-Sans,
               Ubuntu,
               Cantarell,
               "Helvetica Neue",
               Helvetica,
               Arial,
               sans-serif,
               "Apple Color Emoji",
               "Segoe UI Emoji",
               "Segoe UI Symbol";
  font-weight: 300;
  line-height: 150%;
  letter-spacing: -.0225em;
}
p {
  line-height: 1.25em;
  margin-bottom: .5em;
  &:last-child {
    margin-bottom: 0;
  }
}
a {
  text-decoration: none;
}
i {
  font-style: italic;
}
small {
  font-size: .75em;
}
input, textarea {
  letter-spacing: -0.02em;
}

// Generic
h1, h2, h3, h4, h5, h6, b, strong {
  color: var(--text-strong-color);
  font-weight: 700;
}
p, span, small, ul, ol {
  strong {
    color: var(--text-strong-color);
    font-weight: 500;
  }
}

// Custom
[class^="text-big"] {
  margin-bottom: .5em;
  color: var(--text-color);
  line-height: 125%;
  &:last-child {
    margin-bottom: 0;
  }
}
.text-biggest {
  font-size: 2rem;
  letter-spacing: -.0375em;
}
.text-bigger {
  font-size: 1.75rem;
  letter-spacing: -.035em;
}
.text-big {
  font-size: 1.25rem;
  letter-spacing: -.0325em;
}
.text-lead {
  font-size: 1.1em;
  line-height: 150%;
  letter-spacing: -.03em;
}
.text-detail {
  color: hsla(var(--text-color-hsl), .5);
  font-size: .75em;
  line-height: 150%;
  letter-spacing: -.015em;
}
.text-single {
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Responsive

/* 1440 ≤ Window Width */
@media only screen and (min-width: @breakpoints[large-limit-inferior]) {
  html {
    font-size: 22px;
  }
}

/* 1280 ≤ Window Width < 1440 */
@media only screen and (min-width: @breakpoints[medium-limit-inferior]) and (max-width: @breakpoints[medium-limit-superior]) {
  html {
    font-size: 20px;
  }
}

/* 1024 ≤ Window Width < 1280 */
@media only screen and (min-width: @breakpoints[small-limit-inferior]) and (max-width: @breakpoints[small-limit-superior]) {
  html {
    font-size: 18px;
  }
}
