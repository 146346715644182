.index-legend-info {
  border-radius: 10px;
  border: 0.15rem solid var(--shade-color);
  position: absolute;
  bottom: 0.5rem;
  z-index: 1007;
}
.index-legend-info > * {
  color: var(--text-strong-color);
  font-size: 0.55em;
  font-weight: 400;
  background-color: var(--background-color);
  border-radius: 7px;
  line-height: 0.75rem;
  padding-top: 0.25rem;
}
.index-legend-info .index-legend > * {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.index-legend-info .index-legend .index-info {
  width: 24.5rem;
}
.index-legend-info .index-legend .index-info :last-child {
  float: right;
}
.index-legend-info .index-legend .color-list {
  height: 0.5rem;
}
.index-legend-info .index-legend .color-list > * {
  width: 1.2rem;
  height: 0.3rem;
  display: block;
  float: left;
}
