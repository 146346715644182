.agci-button {
  color: white;
  width: 100px;
  border-color: #12d940 !important;
  background-color: #12d940;
  background-image: linear-gradient(hsl(134, 85%, 55%), hsl(134, 85%, 42%));
  filter: brightness(100%);
  transition: border-color 0.35s ease, filter 0.5s ease;
}
  
.agci-button:hover {
  border-color: hsl(134, 85%, 55%);
  background-image: linear-gradient(hsl(134, 85%, 55%), hsl(134, 85%, 42%));
  filter: brightness(110.5%);
}

.login-overlay {
  width: 100%;
  height: 100%;
  z-index: 1500;
  backdrop-filter: blur(2px);
}

.login-overlay-content {
  text-align: center;
  margin: auto 0;
  // top: 50%; 
  // left: 50%;
  // transform: translate(-50%,-50%);
}


.login-overlay-content .auth-buttons {
  margin: auto 0;
  // top: 50%; 
  // left: 50%;
  // transform: translate(-50%,-50%);
}